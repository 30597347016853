import { Typography } from 'components/DesignSystemV2/Typography';
import { IconsContentBookRead } from 'components/Icon/SVGIcons';
import React, { useMemo } from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { HorizontalArrow, VerticalArrow } from '../Animations';
import { Link } from 'gatsby';
import { ButtonV3 } from 'components/ButtonV3';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { toScreen } from 'utils/media-query/responsive.util';
import { RoadMapCard } from 'components/RoadMapCard';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  order: ${({ $order }) => $order};

  .animated-arrow {
    ${toScreen(desktopFirstV3.sm)} {
      visibility: hidden;
    }
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Index = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: ${colorsV2.red10};
`;

const HArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-25%, -50%);
`;

const VArrowWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  ${({ $side }) => $side === 'right' && 'left: 100%;'}
  ${({ $side }) => $side === 'left' && 'right: 100%;'}
  transform: translate(${({ $side }) =>
    $side === 'right' ? '-50%' : '50%'}, 25%);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-top: auto;
`;

export const RoadmapItem = ({
  name,
  summary,
  goals,
  duration,
  target,
  outcome,
  index,
  totalRows,
  url
}) => {
  const row = Math.floor(index / 2);
  const col = index % 2;

  const order = useMemo(() => {
    if (row % 2 === 1) {
      return row * 2 + (2 - col);
    }
    return index + 1;
  }, [row, index, col]);

  const hDirection = row % 2 === 0 ? 'right' : 'left';
  const vSide = order % 2 === 0 ? 'right' : 'left';

  return (
    <ItemWrapper $order={order + 1}>
      <RoadMapCard
        duration={duration}
        target={target}
        outcome={outcome}
        summary={summary}
        goals={goals}
        label={
          <Label>
            {order % 2 === 1 && (
              <HArrowWrapper>
                <HorizontalArrow
                  className="animated-arrow"
                  direction={hDirection}
                />
              </HArrowWrapper>
            )}

            {row > 0 && row < totalRows && col === 0 && (
              <VArrowWrapper $side={vSide}>
                <VerticalArrow className="animated-arrow" side={vSide} />
              </VArrowWrapper>
            )}
            <Index>
              <Typography variant="bold/20-28" color={colorsV2.red100}>
                {index + 1}
              </Typography>
            </Index>
            <div>
              <Typography variant="regular/14-16" color={colorsV2.neutral140}>
                Khoá học
              </Typography>
              <Typography variant="semi-bold/20-28" color={colorsV2.primary100}>
                {name}
              </Typography>
            </div>
          </Label>
        }
        footer={
          url ? (
            <StyledLink to={url}>
              <ButtonV3 block leftIcon={<IconsContentBookRead />}>
                Chi tiết khoá học
              </ButtonV3>
            </StyledLink>
          ) : (
            <ButtonV3 block disabled leftIcon={<IconsContentBookRead />}>
              Chi tiết khoá học
            </ButtonV3>
          )
        }
      />
    </ItemWrapper>
  );
};
