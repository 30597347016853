import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import Button from '@dolstaff/shared/es/Button';
import { LazyImage } from 'components/LazyImage';
import {
  IconArrowChevronLeftArrowRight,
  IconCourseCheck
} from 'components/Icon/SVGIcons';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { getAssetLinkByHandle } from 'utils/getAssetLink';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { toScreen } from 'utils/media-query/responsive.util';
import { SectionV3 } from 'components/SectionV3';

const InnerContent = styled.div`
  /* In case the background image is loading, we use the primary color as fallback */
  background-color: ${colorsV2.primary100};
  background-image: url(${({ $bg }) => $bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 72px;
  border-radius: 32px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  align-items: center;
  gap: 40px;

  ${toScreen(desktopFirstV3.md)} {
    padding: 32px 0 32px 32px;
    gap: 0;
  }

  ${toScreen(desktopFirstV3.sm)} {
    grid-template-columns: 1fr;
    padding-right: 32px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const OfferList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const OfferItem = styled.li`
  display: flex;
  align-items: center;
  gap: 14px;
  color: ${colorsV2.white100};

  svg {
    opacity: 0.6;
  }
`;

const Cta = styled(Button)`
  background-color: ${colorsV2.white100};
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background-color: ${colorsV2.white100};
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 558/397;
  max-width: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const SaleBannerSectionBase = ({ data, onRegisterClick }) => {
  const { title, offers, imageHandle, bgHandle } = data;

  return (
    <SectionV3>
      <ResponsiveContainer>
        <InnerContent $bg={getAssetLinkByHandle({ handle: bgHandle })}>
          <Content>
            <Typography variant="bold/32-40" color={colorsV2.white100} v3>
              {title}
            </Typography>
            <OfferList>
              {offers.map((offer, index) => (
                <OfferItem key={index}>
                  <IconCourseCheck />
                  <Typography
                    variant="medium/16-24"
                    color={colorsV2.white100}
                    v3
                  >
                    {offer}
                  </Typography>
                </OfferItem>
              ))}
            </OfferList>
            <Cta
              type="secondary"
              size="large"
              onClick={onRegisterClick}
              data-testid="register-button"
            >
              <Typography
                variant="medium/16-24"
                color={colorsV2.neutral180}
                type="span"
                v3
              >
                Đăng ký ngay
              </Typography>
              <IconArrowChevronLeftArrowRight color={colorsV2.neutral180} />
            </Cta>
          </Content>
          <ImageWrapper>
            <LazyImage
              handle={imageHandle}
              alt={title}
              width={558}
              height={397}
            />
          </ImageWrapper>
        </InnerContent>
      </ResponsiveContainer>
    </SectionV3>
  );
};
