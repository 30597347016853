import { Typography } from 'components/DesignSystemV2/Typography';
import { ChatBubbleIcon } from 'components/Icon/SVGIcons';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { SectionV3 } from 'components/SectionV3';
import { VideoThumbnail } from 'components/VideoThumbnail';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { getAssetLinkByHandle } from 'utils/getAssetLink';

const Section = styled(SectionV3)`
  padding-top: 0;
  padding-bottom: 0;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 0;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 64px;
`;

export const IeltsJuniorOverview = ({ data }) => {
  const {
    title,
    video: { caption, videoHandle }
  } = data;
  return (
    <Section>
      <ResponsiveContainer>
        <Title>
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            color={colorsV2.black100}
            v3
          >
            {title}
          </Typography>
        </Title>
        <VideoThumbnail
          playInline
          plyrSource={{
            type: 'video',
            sources: [
              {
                src: getAssetLinkByHandle({ handle: videoHandle }),
                type: 'video/mp4'
              }
            ]
          }}
          plyrOptions={{
            controls: ['play-large']
          }}
          caption={caption}
          width={720}
          aspectRatio="720/405"
          center
          captionPosition="top"
          captionAlign="center"
          captionIcon={<ChatBubbleIcon />}
        />
      </ResponsiveContainer>
    </Section>
  );
};
